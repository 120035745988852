import {environment} from '../../environments/environment';

export const API_BASE_URL = environment.apiBaseUrl;

export const MASTER_DATA_CACHED_TIME_IN_MINUTES = 10;

export const APP_CLIENT_ID = 1;
export const APP_SECRET_KEY = "S7avhqJFdtMwtp67zNdv8RQO460Cg0Tj";

export const API_APPLICATION = {
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  REFRESH_TOKEN: '/auth/refresh',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CHANGE_PASSWORD: "/auth/change-password",
  PROMOTIONS: "/promotions",
  TRIP_CATALOGS: "/trip-catalogs",
  PAYMENTS: "/visitor-orders",
  VISITOR_ORDERS: "/visitor-orders",
  PARTNER_ORDERS: "/partner-orders",
  MASTER_TRIP_CATALOG: "/publish/trip-catalogs",
  MASTER_DATA: "/masters",
  MASTER_TRIP: "/publish/trips",
  ADMIN_ACCOUNTS: '/admin-accounts',
  FIXED_TRIPS: '/trips',
  BANK_ACCOUNTS: '/bank-accounts',
  EMAIL_TEMPLATES: '/mail-templates',
  MEMBER_ACCOUNTS: '/member-accounts',
  COLLABORATOR_ACCOUNTS: '/collaborator-accounts',
  AGENT_COLLABORATOR_ACCOUNTS: '/agent-admins/collaborators-accounts',
  SUB_AGENT_ACCOUNTS: '/sub-agent-accounts',
  COLLABORATOR_GROUPS: '/collaborator-groups',
  CONFIGURATIONS: '/configurations',
  TRANSACTIONS: '/transactions',
  PROFILE: '/profile',
  PUSH_NOTIFICATIONS: '/push-notifications',
  NOTIFICATIONS: '/notifications',
  TRANSACTION_HISTORIES: '/profile/transactions',
  BOOKING_HISTORIES: '/profile/orders',
  CARS: '/cars',
  SUPPORT: '/supports',
  PARTNERS: '/partners',
  ORDERS: '/orders',
  AUCTION: '/auctions',
  ACCOUNT: '/accounts',
  COMPLAINT: '/complaints',
  COMMISSION: '/commissions',
  INVENTORY: '/inventories',
  ACCOUNT_HISTORIES: '/account-histories',
  PARTNER_LINK: '/partner-links',
  CAR_GROUPS: '/car-groups',
  SERVICE: '/services',
  ORDER_HISTORIES_CHANGE: '/order-histories-change',
  ADMINISTRATIVE_UNIT: '/administrative-units',
  PRICE_RANGES: '/price-ranges',
  REPORT: 'report',
};

export const NAVIGATE_ROUTING = {
  HOME: "/",
  BACK_TO_LOGIN: '/home/login',
  ORDER: '/home/order',
  ORDER_CONFIRMATION: "/home/order-confirmation",
  REGISTER: '/home/register',
  CHANGE_PASSWORD: '/home/change-password',
  CONTACT: '/home/contact',
  PAGE_NOT_FOUND: '/error/404',
  FORBIDDEN: '/error/403',
  INTERNAL_SERVER_ERROR: '/error/500',
  TRANSACTION_HISTORIES: '/user/transaction-histories',
  BOOKING_HISTORIES: '/user/booking-histories',
  PROFILE: '/user',
  UPDATE_PROFILE: '/user/profile',
  USER_PROMOTION: '/user/promotion',
  USER_LOYALTY: '/user/loyalty',
  COMPLAINT_HISTORY: '/home/complaint',
  DASHBOARD: "/dashboard",
  DASHBOARD_FIXED_ORDER: "/dashboard/fixed-trip-order",
  DASHBOARD_FLEXIBLE_ORDER: "/dashboard/flexible-trip-order",
  SUB_AGENT_ACCOUNTS: "/dashboard/sub-agent-accounts",
};

export const MESSAGE_TYPE = {
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
  WAIT: 'wait',
  ERROR: 'error',
  WARNING: 'warning',
};

export const HttpStatus = {
  ERR_CONNECTION_REFUSED: 0,
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  AMBIGUOUS: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  I_AM_A_TEAPOT: 418,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
};
